import React, { useEffect } from "react";
import { Container, Title, Options, Option, LogoutWrap } from "../styles/dashboard";
import axios from "axios";
import Terms from "../components/terms";
import Prename from "../assets/img/prename.png";
import { auth } from './firebaseConfig';
import { useNavigate } from "react-router-dom";
import { isAuthenticated, getEmail } from "../services/auth";
import Placeholder from '../assets/img/background_login.png';
import DocsImage from '../assets/img/docs.jpg';
import DebtImage from '../assets/img/debt.jpg';
import PaperImage from '../assets/img/paper.jpg';
import PfPjImage from '../assets/img/pfpj.jpg';
import PatrImage from '../assets/img/patr.jpg';
import LogoutIcon from '../assets/sair.png';
import Cookies from "js-cookie";
import moment from "moment";

function Dashboard() {
  const [userToken, setUserToken] = React.useState('');
  const [acceptTerms, setAcceptTerms] = React.useState(true);
  const [services, setServices] = React.useState([
    {
      name: "debt",
      img: DebtImage,
      active: false,
      link: process.env.REACT_APP_DEBT_URL
    },
    {
      name: "docs",
      img: DocsImage,
      active: false,
      description: "O InovaDOCS é a solução definitiva para simplificar a complexa gestão de documentos no setor público. Combinando tecnologias como big data, inteligência artificial e automação de processos, juntamente com nossa experiência em governança, tornamos o controle, a digitalização e a organização de documentos uma tarefa eficiente e segura.",
      link: process.env.REACT_APP_DOCS_URL
    },
    {
      name: "patr",
      img: PatrImage,
      active: false,
      description: "É a solução tecnológica adequada para gerenciar e administrar os bens móveis, possibilitando maior eficiência, agilidade e confiabilidade dos processos de gestão patrimonial, além de gerir e executar os inventários e monitoramento das movimentações de todos os bens móveis.",
      link: process.env.REACT_APP_PATR_URL
    },
    {
      name: "lgpd",
      img: Placeholder,
      active: false,
      description: "Sua Rota Personalizada para Adequação à LGPD. O InovaLGPD é uma solução especializada em consultoria para a implementação e adequação de instituições à Lei Geral de Proteção de Dados (LGPD).",
    },
    {
      name: "paper",
      img: PaperImage,
      active: false,
      description: "É a solução para administrar a gestão e produção de documentos e processos administrativos de forma digital, proporcionando agilidade na elaboração, tramitação de informações/documentos e assinatura digital, bem como realizar o gerenciamento da base de dados de documentos digitalizados.",
    },
    {
      name: "pf/pj",
      img: PfPjImage,
      active: false,
      description: "É uma solução que utiliza dados de pessoas e empresas para melhorar a precisão das decisões tomadas, abordando análises detalhadas, integração de dados, automatização de processos, identificação de tendências e oportunidades de negócio, ajudando a desenvolver estratégias diferenciadas para seu negócio.",
    }]);

  let navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      if (!isAuthenticated()) {
        navigate('/login');
        return;
      }

      await axios.get(`${process.env.REACT_APP_DATA_API}token/${getEmail()}`, {
        headers: {
          'Authorization': "64f7b70cc2a5f9cf8a3a8c08",
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        Cookies.set("@inovally", JSON.stringify(
          {
            cpf: "058.255.620-10",
            email: auth.currentUser.email,
            token: response?.data?.token,
          }),
          { expires: moment().utc(true).add(24, "hours").toDate() }
        );
        setUserToken(response?.data?.token);
      }).catch((err) => {
        console.log(err);
      })
    };

    auth.onAuthStateChanged(checkUser);
  // eslint-disable-next-line
  }, [navigate, auth]);

  const verifyTerms = async () => {
    await axios.get(`${process.env.REACT_APP_DATA_API}user/${getEmail()}`, {
      headers: {
        'Authorization': "64f7b70cc2a5f9cf8a3a8c08",
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (response?.data?.apps) {
        let newServices = services.map((elemento) => {
          if (response.data.apps.hasOwnProperty(elemento.name)) {
            return { ...elemento, active: response.data.apps[elemento.name] };
          }
          return elemento;
        });
        setServices(newServices);
      }
      if (response?.data?.aceite) {
        setAcceptTerms(true)
      } else {
        setAcceptTerms(false)
      }
    }).catch((err) => {
      console.log(err);
    })

    await axios.get(`${process.env.REACT_APP_DATA_API}token/${getEmail()}`, {
      headers: {
        'Authorization': "64f7b70cc2a5f9cf8a3a8c08",
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      Cookies.set("@inovally", JSON.stringify(
        {
          cpf: "058.255.620-10",
          email: auth.currentUser.email,
          token: response?.data?.token,
        }),
        { expires: moment().utc(true).add(24, "hours").toDate() }
      );
      setUserToken(response?.data?.token);
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    verifyTerms();
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) navigate('/logout');
    });
  }, [navigate]);

  return (
    <>
      {!acceptTerms && <Terms />}
      <Container>
        <LogoutWrap>
          <div onClick={() => navigate('/logout')}>
            <img src={LogoutIcon} alt="sair" />
            <span>
              Sair
            </span>
          </div>
        </LogoutWrap>
        <Title>Selecione a aplicação Inovally</Title>
        <Options>
          {services.map((service) => {
            return (service.active ?
              <a href={`${service.link}?token=${userToken}`} key={`service_${service.name}`}>
                <Option className="option" key={`service-${service.name}`} $bgImage={service.img}>
                  <div className="name">
                    <img src={Prename} alt="inovally" />
                    <h3>{service.name}</h3>
                  </div>
                </Option>
              </a>
              :
              <Option className="option" key={`service-${service.name}`} $bgImage={service.img}>
                <div className="name">
                  <img src={Prename} alt="inovally" />
                  <h3>{service.name}</h3>
                </div>
                <div className="description">
                  <p>{service.description} </p>
                </div>
              </Option>
            )
          })}
        </Options>
      </Container>
    </>
  );
}

export default Dashboard;
